.with-search {
  .search-list {
    position: absolute;
    z-index: 10;
    width: 100%;
    max-height: 250px;
    overflow: auto;
    box-shadow: 0px 3px 6px var(--box-shadow-color);
    border-radius: 0px;

    .heading {
      display: flex;
      align-items: center;

      strong {
        flex: 1;
      }

      .fa.fa-times {
        cursor: pointer;
      }
    }

    li {
      cursor: pointer;
    }
  }
  .input-field {
    i.fas.fa-times-circle {
      float: right;
      margin-top: -25px;
      margin-right: 10px;
      color: var(--dark);
      cursor: pointer;
    }
  }

  .form-control.is-invalid {
    background-position: center right 30px !important;
  }
}
