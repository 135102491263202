body {
    background-color: #f5f5f5 !important;
}

.loginbox{
    width: 320px;
    height: 420px;
    background: #fff;
    color: #fff;
    top: 50%;
    line-height: 38px;
    left: 50%;
    position: absolute;
    transform: translate(-50%,-50%);
    box-sizing: border-box;
    padding: 58px 22px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0,0,0,.1);
}

.textTitle{
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: 700;
    color: #000;
    opacity: .6;
}


.textField
{
    border: none;
    border-bottom: 1px solid rgba(0,0,0,.3);
    background: transparent;
    outline: none;
    height: 40px;
    color: #000;
    font-size: 16px;
    width: 100%;
    margin-bottom: 18px;
}

.loginSubmitBtn
{
    border: none;
    outline: none;
    height: 40px;
    background: #2383c6;
    color: #fff;
    font-size: 18px;
    border-radius: 20px;
    margin-top:20px;
    width: 100%;
    margin-bottom: 18px;
}

.loginSubmitBtn:hover
{
    cursor: pointer;
    background: #fff;
    color: #2383c6;
}

.avatar{
    height: auto;
    border-radius: 50%;
    position: absolute;
    top: -50px;
    left: calc(50% - 45px);
    max-width: 85px;
}

.loginHead{
    margin-left: 66px;
    text-align: center;
    font-size: 22px;
    font-weight: 700;
}


