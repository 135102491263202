.form-status-indicator {
  float: right;
  margin-top: -30px;
  margin-right: 10px;
  font-size: 19px;
}

.redux-form-error {
  @extend .form-status-indicator;
  color: var(--red);
}

.redux-form-warning {
  @extend .form-status-indicator;
  color: var(--yellow);
}

.switch-box-status,
.select-field,
.react-date-status,
.tiny-mce-status {
  .redux-form-warning {
    float: unset;
    margin: 0;
    font-size: 80%;
  }

  .redux-form-error {
    @extend .redux-form-warning;
    color: var(--red);
  }
}

.select-field {
  position: relative;

  select.is-invalid {
    background-position: center right 15px !important;
  }
}

.form-control.is-invalid {
  background-position: center right 10px !important;
}

.tiny-mce-wrapper {
  position: relative;
  overflow-y: auto;

  .tiny-mce-editor {
    &.inline {
      position: absolute;
      top: 0;
      padding: 10px;
      height: 580px;
      overflow-y: auto;
    }
  }
}

//TinyMCE Editor

.tox {
  .tox-notification {
    display: none !important;
  }

  .tox-statusbar__branding {
    display: none !important;
  }
}
