//Modal
.custom-modal {
  position: fixed; /* Stay in place */
  z-index: 1021; /* Sit on top */
  left: 0;
  top: 0;
  right: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  .modal-content {
    box-shadow: 0px 3px 6px var(--box-shadow-color);

    .modal-header {
      border-radius: 0px;
      border-bottom: 0;
      padding: 2rem;

      .modal-title,
      button {
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: -0.257143px;
        color: #5c6366;
      }
    }

    .modal-body {
      padding: 0 2rem 2rem 2rem;
    }
  }
}

.container {
  max-width: 100% !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

label {
  font-weight: 500;
}

.breadcrumb {
  background-color: #474aaf !important;
  position: relative;

  li.breadcrumb-item {
    color: #e2dfdf;
    display: inline-block;
  }
  .active {
    color: #fff !important;
    font-weight: 600;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    color: #fff !important;
  }

  h4 {
    color: #fff;
    font-size: 18px;
    display: inline-block;
    margin: 0;
  }

  ol {
    padding: 0;
    margin: 0;
    position: absolute;
    top: 10px;
    right: 15px;
  }
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #aaa;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.divLoader {
  font-size: 20px;
  color: #474aaf;
  top: 77%;
  text-align: center;
  left: 50%;
}

.navbar-nav li:hover .dropdown-menu {
  display: block;
}

.dropdown-menu .dropdown-item:hover {
  background-color: #474aaf;
  color: #fff;
}

.nav-item {
  margin-right: 10px;
}

table thead tr {
  background: #474aaf;
  color: #fff;
}

.tableEdit {
  cursor: pointer;
  background: #28a745;
  color: #fff !important;
  font-size: 12px;
  padding: 10px;
  margin-left: 10px;
  border-radius: 15px;
  text-align: center;
}
// .tableEdit:hover ,.tableDelete:hover ,.tableLM:hover{
//   background-color: #474aaf;
//   color:#fff !important;
// }

.tableDelete {
  cursor: pointer;
  background: #dc3545;
  color: #fff !important;
  font-size: 12px;
  padding: 10px;
  margin-left: 10px;
  border-radius: 15px;
  text-align: center;
}

.tableLM {
  cursor: pointer;
  background: #474aaf;
  color: #fff !important;
  font-size: 12px;
  padding: 10px;
  margin-left: 10px;
  border-radius: 15px;
  text-align: center;
}

.cardTitle {
  font-size: 18px;
  font-weight: 600;
  color: #474aaf;
}

.btn-secondary {
  color: #fff !important;
  background-color: #474aaf !important;
  border-color: #474aaf !important;
  padding: 7px 10px;
}

.border-secondary {
  border-color: #474aaf !important;
}
.page-link {
  color: #474aaf;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #474aaf;
  border-color: #474aaf;
}

/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

.sidebar-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 48px; /* Height of navbar */
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
  font-size: 16px;
  padding: 8px;
}

.nav-link .faIcon {
  margin-right: 4px;
  color: #999 !important;
}

.nav-item.active a span {
  color: #fff !important;
}

.nav-item.active a {
  color: #fff !important;
  background-color: #474aaf !important;
}

.nav-item:hover .faIcon,
.nav-item.active .faIcon {
  color: inherit;
}

.nav-item {
  margin-right: 0px !important;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

/*
 * MultiSelectWithAutoCompleted
 */
.multiSelect {
  .taginputs {
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    display: inline-block;
    padding: 1px 6px;
    color: #555;
    vertical-align: middle;
    border-radius: 4px;
    width: 100%;
    max-width: 100%;
    line-height: 28px;
    cursor: text;
  }

  .tag {
    background: #474aaf;
    position: relative;
    margin-right: 2px;
    margin-bottom: 2px;
    padding: 0.4em 0.6em;
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    cursor: pointer;
    border-radius: 0.25em;
  }

  .multiselectInput {
    position: relative;
    top: 0px;
    left: 0px;
    border-color: transparent;
    box-shadow: none;
    opacity: 1;
    background: none 0% 0% / auto repeat scroll padding-box border-box
      rgba(0, 0, 0, 0);
    width: auto;
    outline: none;
  }

  .inputSpan {
    position: relative;
  }

  .removeTag {
    cursor: pointer;
  }

  .list-group {
    height: 100px;
    overflow-x: auto;
    position: absolute;
    width: 94%;
    z-index: 99999;
  }

  .list-group-item:hover {
    background: #474aaf;
    color: white;
    cursor: pointer;
  }
}

/*
 * Tabs
 */

li.tab-item {
  padding: 8px;
  color: #474aaf;
  font-size: 16px;
}

li.tab-item:hover {
  cursor: pointer;
}

li.tab-item.active {
  border-bottom: 3px solid #474aaf;
}

li.tab-item.inactive {
  cursor: not-allowed;
}

.tox-promotion {
  display: none !important;
}
