.admin-role-container {
  padding: 10px;
  .accordion {
    margin: 20px 0px;

    .card {
      box-shadow: 0px 3px 3px var(--box-shadow-color);
      border: none;
    }

    .card-header {
      background: #474aaf;
      border-radius: 0px;
      padding: 0px;
      display: flex;

      .title {
        flex: 1;
        color: white;
        font-size: 18px;
        height: 50px;
        cursor: pointer;
        padding-left: 20px;
        display: flex;
        align-items: center;
      }

      .actions,
      .stats {
        height: 50px;
        display: flex;
        align-items: center;
        padding-right: 20px;
        color: white;

        i {
          cursor: pointer;
          margin: 0px 8px;
          font-size: 15px;
          color: white;
        }

        i:hover {
          color: var(--success);
        }
      }
    }

    .card-body {
      .course-maker-object {
        display: flex;
        align-items: center;

        .title {
          flex: 1;

          span {
            color: var(--gray);
          }

          i {
            color: var(--primary);
            font-size: 15px;
          }
        }

        .actions {
          i {
            margin-right: 0px;
            margin-left: 20px;
            cursor: pointer;
            font-size: 15px;
          }
        }
      }
    }
  }

  .accordion:first-child {
    margin-top: 0px;
  }

  .role-editor-form {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 20px;

    .header {
      display: flex;
      align-items: center;

      .title {
        flex: 1;
      }
    }
  }
}
